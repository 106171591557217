@import 'src/app/style/utility/variables';

.background-primary {
  background-color: $primary-color;
}

.background-primary-lighter {
  background-color: $primary-lighter-color;
}

.background-grey {
  background-color: $text-light;
}

.text-primary-color {
  color: $primary-color;
}

.text-error {
  color: $warn-lighter-color;
}

.text-warn {
  color: $warn-lighter-color;
}

.text-light-color {
  color: $text-light;
}
